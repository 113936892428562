const queryString = require('query-string')

const defaultConfig = {
  elementId: 'root-widget',
  language: 'es',
  type: 'form'
}

const getIframeHeight = ({ type, height }) => {
  if (height) {
    return height
  }
  switch (type) {
    case 'form':
      return 830
    case 'button':
      return 50
    case 'preview':
      return 400
    case 'list':
      return 492
  }
}

const getIframeWidth = ({ type, width }) => {
  if (width) {
    return width
  }
  switch (type) {
    case 'form':
      return 360
    case 'button':
      return 175
    case 'preview':
      return 320
    case 'list':
      return '100%'
  }
}

export const build = (config) => {
  const buildConfig = {
    ...defaultConfig,
    ...config,
    id: config.id || (config.ids && config.ids.join(','))
  }
  if (!buildConfig.host) {
    throw Error('[BookingWidget] host is required in Widget config')
  } else if (!buildConfig.id) {
    throw Error('[BookingWidget] id is required in Widget config')
  }
  const iframeElement = document.createElement('iframe')
  iframeElement.src = `${
    buildConfig.host
  }/widget/?${queryString.stringify(buildConfig, { arrayFormat: 'comma' })}`
  iframeElement.frameBorder = 0
  iframeElement.height = getIframeHeight(buildConfig)
  iframeElement.width = getIframeWidth(buildConfig)
  var el = document.getElementById(buildConfig.elementId)
  el.parentNode.replaceChild(iframeElement, el)
}
